{
  "nav": {
    "adfcycles": "ADF Cycles",
    "afdb": "African Development Bank Group",
    "by_adfcycles": "By ADF Cycles",
    "by_country": "By Country & Region",
    "by_financing": "By Sources of Financing",
    "by_high5": "By High 5",
    "by_sector": "By Sector",
    "by_sovereign": "Sovereign / Non-sovereign",
    "by_status": "By Status",
    "by_year": "By Year",
    "clear_filters": "Clear Filters",
    "countries": "Countries & Regions",
    "demo": "This is a demo site currently under development",
    "done": "Done",
    "filters": "Filters",
    "financing": "Sources of Financing",
    "high5s": "High 5s",
    "home": "Home",
    "mapafrica": "MapAfrica",
    "non_sovereign": "Non-sovereign",
    "projects": "Projects",
    "results": "Results",
    "search": "Search",
    "sectors": "Sectors",
    "sovereign": "Sovereign",
    "status_approved": "Approved",
    "status_cancelled": "Cancelled",
    "status_completed": "Completed",
    "status_ongoing": "Ongoing"
  },
  "locale": {
    "en": "English",
    "fr": "Français"
  },
  "home": {
    "approval_date": "Approval Date",
    "commitment": "Commitment",
    "csv": "CSV",
    "description": "Explore African Development Bank funded activities",
    "download": "Export data",
    "end_date": "End date",
    "iati_identifier":"IATI identifier",
    "identifier":"Project code",
    "json": "JSON",
    "map_title": "Map of all projects",
    "multinational": "Multinational",
    "none_selected": "None selected",
    "projects": "Projects",
    "project_title": "Title",
    "project_country": "Country",
    "sector": "Sector",
    "sovereign_non": "Sovereign / Non Sovereign",
    "status": "Status",
    "title": "Overview",
    "unknown": "Unknown",
    "xlsx": "XLSX",
    "xml": "XML"
  },
  "map": {
    "project": "project",
    "projects": "projects",
    "reset": "Reset"
  },
  "stats": {
    "projects": "Number of Projects",
    "status_approved": "Approved",
    "status_cancelled": "Cancelled",
    "status_completed": "Completed",
    "status_ongoing": "Ongoing",
    "total_commitments": "Total Commitments"
  },
  "middle": {
    "in_million_ua": "in UA million",
    "multinational": "Multinational",
    "top_countries": "Countries",
    "top_sectors": "Sectors",
    "top_sources": "Top Sources of Financing",
    "top_themes": "High 5s",
    "unknown": "Unknown"
  },
  "countries": {
    "description": "Explore African Development Bank funded activities in {country}",
    "map_title_countries": "Map of projects by country",
    "map_title_regions": "Map of projects by region"
  },
  "sectors": {
    "description": "Explore African Development Bank funded activities in the {sector} sector",
    "map_title": "Map of projects by sector"
  },
  "results": {
    "country": "Country",
    "delivered": "Actual",
    "expected": "Planned",
    "high_5": "High 5",
    "indicator_name": "Indicator Name",
    "results_management_framework": "Results Management Framework",
    "sector": "Sector"
  },
  "projects": {
    "adf_cycle": "ADF Cycle",
    "aid_type": "Aid Type",
    "approval_date": "Approval Date",
    "beneficiaries": "Beneficiaries",
    "climate_safeguards_cat": "Climate Safeguards Categorization",
    "commitment": "Commitment",
    "commitments": "Commitments",
    "contact_details": "Contact Details",
    "country": "Country",
    "dac_sector_code": "DAC Sector Code",
    "date": "Date",
    "disbursement": "Disbursement",
    "disbursements": "Disbursements",
    "documents": "Documents",
    "email": "Email",
    "end_date": "End date",
    "environmental_cat": "Environmental Category",
    "export": "Export",
    "finance_id": "Finance Type ID",
    "finance_type": "Finance Type",
    "financial_information": "Financial information",
    "flow_type": "Flow Type",
    "fragility_lens_assessment": "Fragility Lens Assessment",
    "gender_marker_system_cat": "Gender Marker System Categorization",
    "geographic_location": "Geographic Location",
    "iati_identifier": "IATI identifier",
    "indicator": "Indicator",
    "indicator_actual": "Actual Value",
    "indicator_actual_year": "Actual Year",
    "indicator_measure": "Indicator Measure",
    "indicator_target": "Target Value",
    "indicator_target_year": "Target Year",
    "indicator_title": "Notes",
    "info": "Information",
    "interest": "Interest Payments",
    "last_disbursement": "Last Disbursement Planned Date",
    "loading": "Loading...",
    "loans": "Loan Repayments",
    "location": "Location",
    "name": "Name",
    "no_results": "No results",
    "non_sovereign": "Non-sovereign",
    "overview": "Overview",
    "participating_org": "Participating Organizations",
    "planned": "planned",
    "project_classification": "Project Classification",
    "planned_completion_date": "Planned Completion Date",
    "project_general_description": "Project General Description",
    "project_objectives": "Project Objectives",
    "project_search": "Project search",
    "project_summary": "Project Summary",
    "provider": "Provider",
    "results": "Results",
    "search": "Search",
    "search_for_project": "Search for a project",
    "search_results": "Search Results",
    "sector": "Sector",
    "signature_date": "Signature Date",
    "sovereign": "Sovereign",
    "sovereign_non_sovereign": "Sovereign / Non-Sovereign",
    "start_date": "Start date",
    "status": "Status",
    "stories": "Stories",
    "theme": "High 5",
    "topics": "Topics",
    "totals": "Totals",
    "unknown": "Unknown",
    "untitled": "Untitled",
    "value": "Value"
  },
  "footer": {
    "afdb": "African Development Bank Group",
    "disclosure_1": "Disclosure and Access to Information",
    "disclosure_2": "Independent Recourse Mechanism",
    "disclosure_html_1": "Do you have a concern about a Bank-financed project? Individuals, workers and communities who are (or may be) adversely impacted by AfDB-financed projects are encouraged to contact the project-level grievance mechanism or submit their complaints to the",
    "disclosure_html_2": "Independent Recourse Mechanism (IRM)",
    "disclosure_html_3": "The",
    "disclosure_html_4": "Disclosure and Access to Information (DAI) policy",
    "disclosure_html_5": "is a reaffirmation of the Bank Group’s commitment, to carry out its development activities in an open and transparent manner. The policy is grounded on the principles of good governance, particularly transparency, accountability and sharing of information in its operations. It establishes the disclosure requirements for documents and information the AfDB produces or requires to be produced",
    "disclosure_url_1": "https://irm.afdb.org/",
    "disclosure_url_2": "https://www.afdb.org/en/disclosure-and-access-to-information",
    "glossary": "Glossary",
    "rss": "RSS Feeds",
    "sitemap": "Sitemap",
    "terms": "Terms & Conditions"
  }
}
