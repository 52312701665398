{
  "nav": {
    "adfcycles": "Cycles du FAD",
    "afdb": "Groupe de la Banque africaine de développement",
    "by_adfcycles": "Par Cycles du FAD",
    "by_country": "Par Pays & Région",
    "by_financing": "Par Sources de Financement",
    "by_high5": "Par High 5",
    "by_sector": "Par Secteur",
    "by_sovereign": "Souverain / Non souverain",
    "by_status": "Par Statut",
    "by_year": "Par Année",
    "clear_filters": "Effacer les filtres",
    "countries": "Pays & Région",
    "demo": "Il s'agit d'un site de démonstration en cours de développement",
    "done": "Fait",
    "filters": "Filtres",
    "financing": "Sources de Financement",
    "high5s": "High 5s",
    "home": "Accueil",
    "mapafrica": "MapAfrica",
    "non_sovereign": "Non souverain",
    "projects": "Projets",
    "results": "Résultats",
    "search": "Recherche",
    "sectors": "Secteurs",
    "sovereign": "Souverain",
    "status_approved": "Approuvé",
    "status_cancelled": "Annulé",
    "status_completed": "Achevé",
    "status_ongoing": "En cours"
  },
  "locale": {
    "en": "English",
    "fr": "Français"
  },
  "home": {
    "approval_date": "Date d'approbation",
    "commitment": "Engagement",
    "csv": "CSV",
    "description": "Explorez les activités financées par la Banque africaine de développement",
    "download": "Exporter des données",
    "end_date": "Date de fin",
    "iati_identifier": "Identifiant IATI",
    "identifier": "Code projet",
    "json": "JSON",
    "map_title": "Carte de tous les projets",
    "multinational": "Multinational",
    "none_selected": "Aucun sélectionné",
    "projects": "Projets",
    "project_title": "Titre",
    "project_country": "Pays",
    "sector": "Secteur",
    "sovereign_non": "Souverain / Non Souverain",
    "status": "Statut",
    "title": "Aperçu",
    "unknown": "Inconnu",
    "xlsx": "XLSX",
    "xml": "XML"
  },
  "map": {
    "project": "projet",
    "projects": "projets",
    "reset": "Réinitialiser"
  },
  "stats": {
    "projects": "Nombre de Projets",
    "status_approved": "Approuvé",
    "status_cancelled": "Annulé",
    "status_completed": "Achevé",
    "status_ongoing": "En cours",
    "total_commitments": "Total des Engagements"
  },
  "middle": {
    "in_million_ua": "en million d'UC",
    "multinational": "Multinational",
    "top_countries": "Pays",
    "top_sectors": "Secteurs",
    "top_sources": "Principales Sources de Financement",
    "top_themes": "High 5s",
    "unknown": "Inconnu"
  },
  "countries": {
    "description": "Explorez les activités financées par la Banque africaine de développement en {country}",
    "map_title_countries": "Carte des projets par pays",
    "map_title_regions": "Carte des projets par région"
  },
  "sectors": {
    "description": "Explorez les activités financées par la Banque africaine de développement dans le secteur {sector}",
    "map_title": "Carte des projets par secteur"
  },
  "results": {
    "country": "Pays",
    "delivered": "Actuel",
    "expected": "Prévu",
    "high_5": "High 5",
    "indicator_name": "Nom de l'indicateur",
    "results_management_framework": "Cadre de Gestion des Résultats",
    "sector": "Secteur"
  },
  "projects": {
    "adf_cycle": "Cycle du FAD",
    "aid_type": "Type d'aide",
    "approval_date": "Date d'approbation",
    "beneficiaries": "Bénéficiaires",
    "climate_safeguards_cat": "Catégorisation des mesures de sauvegarde climatique",
    "commitment": "Engagement",
    "commitments": "Engagements",
    "contact_details": "Coordonnées",
    "country": "Pays",
    "dac_sector_code": "Code du secteur DAC",
    "date": "Date",
    "disbursement": "Décaissement",
    "disbursements": "Décaissements",
    "documents": "Documents",
    "email": "Courriel",
    "end_date": "Date de fin",
    "environmental_cat": "Catégorie environnementale",
    "export": "Exportation",
    "finance_id": "ID du Type de Financement",
    "finance_type": "Type de financement",
    "financial_information": "Informations financières",
    "flow_type": "Type de flux",
    "fragility_lens_assessment": "Évaluation de la lentille de fragilité",
    "gender_marker_system_cat": "Catégorisation des systèmes de marqueurs de genre",
    "geographic_location": "Situation géographique",
    "iati_identifier": "Identifiant IATI",
    "indicator": "Indicateur",
    "indicator_actual": "Valeur Actuelle",
    "indicator_actual_year": "Année Actuelle",
    "indicator_measure": "Indicateur Mesure",
    "indicator_target": "Valeur Cible",
    "indicator_target_year": "Année Cible",
    "indicator_title": "Notes",
    "info": "Informations",
    "interest": "Paiements d'intérêts",
    "last_disbursement": "Date prévue du dernier décaissement",
    "loading": "Chargement...",
    "loans": "Remboursements de prêts",
    "location": "Emplacement",
    "name": "Nom",
    "no_results": "Aucun résultat",
    "non_sovereign": "Non souverain",
    "overview": "Aperçu",
    "participating_org": "Organisations participantes",
    "planned": "prévue",
    "project_classification": "Classification du projet",
    "planned_completion_date": "Date d'achèvement prévue",
    "project_general_description": "Description générale du projet",
    "project_objectives": "Objectifs du projet",
    "project_search": "Recherche de projets",
    "project_summary": "Résumé du projet",
    "provider": "Fournisseur",
    "results": "Résultats",
    "search": "Recherche",
    "search_for_project": "Rechercher un projet",
    "search_results": "Résultats de la Recherche",
    "sector": "Secteur",
    "signature_date": "Date de signature",
    "sovereign": "Souverain",
    "sovereign_non_sovereign": "Souverain / Non Souverain",
    "start_date": "Date de début",
    "status": "Statut",
    "stories": "Histoires",
    "theme": "High 5",
    "topics": "Thèmes",
    "totals": "Totaux",
    "unknown": "Inconnu",
    "untitled": "Sans titre",
    "value": "Valeur"

  },
  "footer": {
    "afdb": "Groupe de la Banque africaine de développement",
    "disclosure_1": "Diffusion et accès à l'information",
    "disclosure_2": "Mécanisme Indépendant de Recours",
    "disclosure_html_1": "Avez-vous des préoccupations concernant un projet financé par la Banque ? Les personnes, travailleurs et les communautés qui sont (ou peuvent être) affectés négativement par les projets financés par la Banque africaine de développement (BAD) sont encouragés à contacter le mécanisme de règlement des griefs au niveau du projet ou à soumettre leurs plaintes au",
    "disclosure_html_2": "Mécanisme Indépendant de Recours (MIR)",
    "disclosure_html_3": "La politique de",
    "disclosure_html_4": "Diffusion et d'Accès à l'Information (DAI)",
    "disclosure_html_5": "est une réaffirmation de l'engagement du Groupe de la Banque à mener ses activités de développement de manière ouverte et transparente. La politique repose sur les principes de bonne gouvernance, en particulier la transparence, la responsabilité et le partage d'informations dans ses opérations. Elle établit les exigences de divulgation pour les documents et les informations produits ou requis par la BAD",
    "disclosure_url_1": "https://irm.afdb.org/fr",
    "disclosure_url_2": "https://www.afdb.org/fr/politique-de-diffusion-et-dacces-linformation-dai",
    "glossary": "Glossaire",
    "rss": "Flux RSS",
    "sitemap": "Plan du site",
    "terms": "Conditions générales d'utilisation"
  }
}
